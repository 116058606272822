
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import Switches from "vue-switches";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    Switches
  },
  data() {
    return {
      notfication_settings: [],
      orders: [],
      users_dateils: [],

      Users: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchUsers: null,
      UsersSearchMode: false,
      searchModel: "",
      state: state
    };
  },
  methods: {
    addUsers() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addUsers",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name"
          },

          {
            model: "image",
            type: "file",
            label: "name"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.user_type_id = 10;
              this.http.do("vendor-catogries", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    AddSubCategory() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "popups.name"
          },
          {
            model: "name_ar",
            type: "text",
            label: "popups.name_ar"
          },

          {
            model: "image",
            type: "file",
            label: "popups.file"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.parent_id = this.$route.params.id;
              this.http.do("vendor-catogries", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    changeStates(app) {
      var status = 0;
      if (app.status == 1) {
        status = 0;
      }
      if (app.status == 0) {
        status = 1;
      }
      this.http
        .post("users/update-status", { id: app.id, status: status })
        .then(res => {
          if (res) {
            this.get(1);
          }
        });
    },
    editUsers(app) {
      var data = {
        title: "popups.name",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name",
            value: app.name
          },
          {
            model: "name",
            type: "text",
            label: "popups.name_ar",
            value: app.name_ar
          },
          {
            model: "image",
            type: "file",
            label: "popups.file",
            value: app.name
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.put("vendor-catogries", app.id, obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    updateUsers(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "name"
          },

          {
            model: "image",
            type: "file",
            label: "name"
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.emp_id = app.id;
              this.http.do("vendor-catogries", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    search() {
      this.UsersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("users/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
          user_type: 1
        })
        .then(res => {
          this.Users = res.data;
          console.log("######## files", this.Users);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.UsersSearchMode = false;
      this.get(this.page);
    },

    getUsers() {
      this.http.get("users").then(res => {
        this.Users = res.data;
      });
    },
    switchStatus(app) {
      // this.status = !this.status
      app.status = !app.status;
      this.http
        .put("vendor-catogries", app?.id, { status: app.status })
        .then(res => {
          if (res.status) {
            this.get(this.page);
          }
        });
    },
    deleteUsers(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage"
      };
      this.popup.confirm(data).then(resp => {
        if (resp) {
          console.log("###");
          this.http.delete("users", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      let cateogries = this.$route.params.id;
      let api = "";
      if (cateogries == 5) api = "vendor-catogries/mulim-services";
      else {
        api = "vendor-catogries/paginate";
      }

      this.http
        .post(api, {
          limit: this.limit,
          page: page,
          table:this.$route.params.table,
          parent_id: this.$route.params.id,
          user_type: 10
        })
        .then(res => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Users = res.data;
          res.data.forEach(item => {
            this.notfication_settings.push(item.notfication_settings);
          });

          res.data.forEach(item => {
            this.notfication_settings.push(item.notfication_settings);
          });

          res.data.forEach(item => {
            this.orders.push(item.orders);
          });

          res.data.forEach(item => {
            this.users_dateils.push(item.users_dateils);
          });
        });
    }
  },
  created() {
    this.get(1);
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.service.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button class="btn-close text-light" v-if="UsersSearchMode" @click="cancelappsearchMode()"></button>
        <button type="button" @click="AddSubCategory()" class="btn btn-light float-end mb-4">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t('services.id') }}</th>
          <th scope="col">{{ $t('services.name') }}</th>
          <th scope="col">{{ $t('services.name_ar') }}</th>
          <th scope="col">{{ $t('services.image') }}</th>
          <th scope="col">{{ $t('services.status') }}</th>

          <th scope="col">{{ $t('services.created') }}</th>
          <th scope="col">{{ $t('services.updated') }}</th>
          <th scope="col">{{ $t('services.operations') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in Users" :key="app" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.name_ar }}</td>

          <td>
            <img
              :src="$store.state?.auth?.mediaUrl + app?.image?.split('public')[1]"
              style="width: 64px;height: 64px;"
              alt
            />
          </td>

          <td>
            <switches @change="switchStatus(app)" :value="app.status" color="success" class="ms-1"></switches>
          </td>

          <td>{{ app?.created.split('T')[0] }}</td>
          <td>{{ app?.updated.split('T')[0] }}</td>

          <td class="d-flex">
            <router-link
              :to="'/mulim-orders/' + app.id"
              class="btn btn-primary mx-1"
              href="javascript: void(0);"
              role="button"
            >
              {{
              $t("popups.orders") }}>
            </router-link>
            <a
              class="btn btn-primary mx-1"
              @click="editUsers(app)"
              href="javascript: void(0);"
              role="button"
            >
              {{
              $t("popups.edit") }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">
            {{
            p
            }}
          </a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




